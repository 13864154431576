import React, { useState, useEffect } from 'react';
import { useUser } from './UserContext';
import CommentItem from './CommentItem';

const Comments = ({ responseId }) => {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [sortAscending, setSortAscending] = useState(true);
  const [includeQualitySort, setIncludeQualitySort] = useState(true);
  const {guid, username} = useUser();
  const dbURI = process.env.REACT_APP_DB_API_URL || 'http://localhost:3001';

  useEffect(() => {
    if (responseId) {
      fetchComments();
    }
  }, [responseId]);

  const fetchComments = async () => {
    if (!responseId) {
      console.error('No responseId provided');
      return;
    }
    try {
      const res = await fetch(`${dbURI}/comments/${responseId}`);
      const data = await res.json();
      setComments(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Error fetching comments:', error);
      setComments([]);
    }
  };

  const handleSubmit = async (e, parentCommentId = null) => {
    e.preventDefault();
    if (!guid) {
      alert('You must be logged in to comment');
      return;
    }
    if (!newComment.trim()) {
      alert('Comment cannot be empty');
      return;
    }
    const res = await fetch(`${dbURI}/comment`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        responseId,
        userId: guid,
        username: username || 'Anonydawg',
        content: newComment,
        parentCommentId
      })
    });
    const data = await res.json();
    if (parentCommentId) {
      setComments(comments.map(comment => 
        comment._id === parentCommentId 
          ? { ...comment, replies: [...(comment.replies || []), data] }
          : comment
      ));
    } else {
      setComments([data, ...comments]);
    }
    setNewComment('');
  };

  const handleVote = async (commentId, voteType, direction) => {
    if (!commentId || commentId === 'undefined') {
      console.error('Invalid comment ID');
      return;
    }

    try {
      const res = await fetch(`${dbURI}/comment/${commentId}/${voteType}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ direction })
      });
      
      if (!res.ok) {
        throw new Error(`HTTP error! status: ${res.status}`);
      }

      const updatedComment = await res.json();
      
      const updateCommentRecursively = (comments) => {
        return comments.map(comment => {
          if (comment._id === updatedComment._id) {
            return { ...updatedComment, replies: comment.replies };
          }
          if (comment.replies) {
            return {
              ...comment,
              replies: updateCommentRecursively(comment.replies)
            };
          }
          return comment;
        });
      };

      setComments(prevComments => updateCommentRecursively(prevComments));
    } catch (error) {
      console.error('Error updating vote:', error);
      // Optionally, show an error message to the user
    }
  };

  const handleEmotion = async (commentId, emotion) => {
    const res = await fetch(`${dbURI}/comment/${commentId}/emotion`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ emotion })
    });
    const updatedComment = await res.json();
    
    const updateCommentRecursively = (comments) => {
      return comments.map(comment => {
        if (comment._id === updatedComment._id) {
          return {
            ...comment,
            emotion: updatedComment.emotion,
            emotionCounts: updatedComment.emotionCounts
          };
        }
        if (comment.replies) {
          return {
            ...comment,
            replies: updateCommentRecursively(comment.replies)
          };
        }
        return comment;
      });
    };

    setComments(updateCommentRecursively(comments));
  };

  const handleReply = async (parentCommentId, replyContent) => {
    if (!replyContent.trim()) {
      alert('Reply cannot be empty');
      return;
    }
    const res = await fetch(`${dbURI}/comment`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        responseId,
        userId: guid,
        username: username || 'Anonydawg',
        content: replyContent,
        parentCommentId
      })
    });
    const data = await res.json();
    setComments(comments => updateCommentsWithNewReply(comments, parentCommentId, data));
  };

  const updateCommentsWithNewReply = (comments, parentCommentId, newReply) => {
    return comments.map(comment => {
      if (comment._id === parentCommentId) {
        return {
          ...comment,
          replies: [...(comment.replies || []), newReply]
        };
      }
      if (comment.replies) {
        return {
          ...comment,
          replies: updateCommentsWithNewReply(comment.replies, parentCommentId, newReply)
        };
      }
      return comment;
    });
  };

  const toggleSort = () => {
    setSortAscending(!sortAscending);
  };

  const toggleQualitySort = () => {
    setIncludeQualitySort(!includeQualitySort);
  };

  const sortedComments = [...comments].sort((a, b) => {
    if (includeQualitySort) {
      const qualityDiff = b.ratings.quality - a.ratings.quality;
      if (qualityDiff !== 0) return qualityDiff;
    }
    const dateA = new Date(a.createdAt);
    const dateB = new Date(b.createdAt);
    return sortAscending ? dateA - dateB : dateB - dateA;
  });

  return (
    <div className="comments-section">
      <div className="comments-content">
        <span className='arena-title'>Enter the Arena</span>
        <button className='sorting-options' onClick={toggleSort}>
          {sortAscending ? "Oldest Showing First" : "Newest Showing First"}
        </button>
        <button className='sorting-options' onClick={toggleQualitySort}>
          {includeQualitySort ? "Quality Included" : "Quality Not Included"}
        </button>
        {guid ? (
          <div className="comment-form">
            <form id="comment-form" onSubmit={handleSubmit}>
              <textarea
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                rows={2}
                cols={50}
                className="comment-textarea"
                placeholder="Compose your masterpiece..."
              />
              <button className='comment-button' type="submit">let it be written...</button>
            </form>
          </div>
        ) : (
          <p>Please log in to comment</p>
        )}
        <div className="comments-list">
          {sortedComments.map((comment) => (
            <CommentItem 
              key={comment._id} 
              comment={comment} 
              handleVote={handleVote}
              handleReply={handleReply}
              handleEmotion={handleEmotion}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Comments;