import React from 'react';
import { Link} from 'react-router-dom';

const s3URI = process.env.REACT_APP_S3_URI || 'https://haikuthenews-responses.s3.amazonaws.com';

const WalterCronkiteResult = ({ response }) => (
    response.walterCronkiteResult ? (
        <div className='walter-cronkite-table-wrapper'>
            <table className="walter-cronkite-table">
                <tbody>
                    <tr>
                        <td rowSpan="3">
                            {response.url ? (
                                <div className='uploaded-image-url'>
                                    <a href={response.url} target="_blank" rel="noopener noreferrer">
                                        <img src={`${s3URI}/${response.screenshot}`} alt={`Holder`} />
                                    </a>
                                </div>
                            ) : (
                                <div className='uploaded-image-image'>
                                    <a href={`${s3URI}/${response.screenshot}`} target="_blank" rel="noopener noreferrer">
                                        <img src={`${s3URI}/${response.screenshot}`} alt={`Holder`} />
                                    </a>
                                </div>
                            )}
                        </td>
                        <td colSpan="1" style={{ width: '0.1%', whiteSpace: 'nowrap' }}>author:</td>
                        <td colSpan="1" className="walter-cronkite-ellipsis-text">
                            <Link to={`/author/${response.walterCronkiteResult.Author}`}>
                                {response.walterCronkiteResult.Author}
                            </Link>
                        </td>
                        <td colSpan="1" style={{ width: '0.1%', whiteSpace: 'nowrap' }}>institution:</td>
                        <td colSpan="1" className="walter-cronkite-ellipsis-text">
                            <Link to={`/institution/${response.walterCronkiteResult.Institution}`}>
                                {response.walterCronkiteResult.Institution}
                            </Link>
                        </td>
                        <td colSpan="1" style={{ width: '0.1%', whiteSpace: 'nowrap', textSizeAdjust: '75%' }}>
                            <Link to={`/author/${response.walterCronkiteResult.Author}/institution/${response.walterCronkiteResult.Institution}`}>
                                    ¿porque no los dos?
                            </Link>
                            </td>

                    </tr>
                    <tr>
                        <td colSpan="1">tl;dr</td>
                        <td colSpan="4" style={{ maxWidth: '50%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {response.url ? (
                                <a href={response.url} target="_blank" rel="noopener noreferrer">
                                    {response.walterCronkiteResult.tldr}
                                </a>
                            ) : (
                                response.walterCronkiteResult.tldr
                            )}
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="1">deeper:</td>
                        <td colSpan="4" style={{ maxWidth: '50%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{response.walterCronkiteResult.Explanation}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    ) : (
        <div className='uploaded_image'>
            <br />
            {response.url ? (
                <div className='uploaded-image-url'>
                    <a href={response.url} target="_blank" rel="noopener noreferrer">
                        <img src={`${s3URI}/${response.screenshot}`} alt={`Holder`} />
                    </a>
                </div>
            ) : (
                <div className='uploaded-image-image'>
                    <a href={`${s3URI}/${response.screenshot}`} target="_blank" rel="noopener noreferrer">
                        <img src={`${s3URI}/${response.screenshot}`} alt={`Holder`} />
                    </a>
                </div>
            )}
        </div>
    )
);

export default WalterCronkiteResult;

