import React, { useEffect, useState } from 'react';
import { useUser } from './UserContext'; // Corrected import path

const FormComponent = ({ handleSubmit, url, setUrl, option, setOption, loadingMessage }) => {
    const { generations } = useUser();
    const [canSubmit, setCanSubmit] = useState(false);

    useEffect(() => {
        setCanSubmit(generations > 0);
    }, [generations]);

    if (!canSubmit) {
        return <p>You do not have enough generation points to submit. <span><a href="https://buy.stripe.com/cN28wP4PW49GgmY6oo" target="_blank">Git some!</a></span></p>;
    }

    return (
        <form onSubmit={handleSubmit}>
            <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => {
                        if (e.target.files[0]) {
                            setUrl(URL.createObjectURL(e.target.files[0]));
                        }
                    }}
                    style={{ display: 'none' }}
                    id="fileInput"
                />
                <label htmlFor="fileInput" className='preview-image-container'>
                    {url ? (
                        url.startsWith('blob') || /\.(jpeg|jpg|gif|png)$/.test(url) ? (
                            <img src={url} alt="Preview" style={{ width: 'auto', maxHeight: '50px' }} />
                        ) : (
                            'Click Button ⬇️'
                        )
                    ) : (
                        'Upload Image <-'
                    )}
                </label>
                <input
                    type="text"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    placeholder="<- Click there or Enter URL here"
                    className="submit-form-textbox"
                />
                <select value={option} onChange={(e) => setOption(e.target.value)} >
                    <option value="haiku">Haiku the News</option>
                    <option value="thing">What is this?</option>
                    <option value="story">Generate Backstory</option>
                    <option value="eli5">Explain Like I'm 5</option>
                    <option value="lols">LOL (limericks)</option>
                    <option value="crypto">Crypto Guru</option>
                    <option value="emoji">📸💾🖌️📺🎤</option>
                    <option value="geo">🌎</option>
                </select>
                <div className="submit-button-container">
                    <button type="submit">Submit the image or URL</button>
                </div>
            </div>
            {/* {loadingMessage && <p>{loadingMessage}</p>} */}
        </form>
    );
};

export default FormComponent;
