import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FilteredResponses from '../filtered_responses';

const UserPage = () => {
    const { username } = useParams();
    const [userExists, setUserExists] = useState(false);
    const [loading, setLoading] = useState(true);
    const dbURI = process.env.REACT_APP_DB_API_URL || 'http://192.168.0.83:3001';

    // console.log('UserPage, username:', username);

    useEffect(() => {
        const checkUser = async () => {
            try {
                const response = await fetch(`${dbURI}/check-user/${username}`);
                const data = await response.json();
                // console.log('User check response:', data);
                setUserExists(data.exists);
            } catch (error) {
                console.error('Error checking user:', error);
            } finally {
                setLoading(false);
            }
        };
        checkUser();
    }, [username, dbURI]);

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!userExists) {
        return <div>User not found App</div>;
    }

    return <FilteredResponses username={username} />;
};

export default UserPage;