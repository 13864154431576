import React, { useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Navigation({ isUserHidden, user }) {
    const navigate = useNavigate();
    const navRef = useRef(null);

    const handleHomeClick = () => {
        navigate('/');
        window.location.reload();
    };

    useEffect(() => {
        if (navRef.current) {
            navRef.current.scrollLeft = 0;
        }
    }, [isUserHidden]);

    return (
        <div ref={navRef} className={`navigation ${user ? (isUserHidden ? 'user-hidden' : '') : ''}`}>
            <nav>
                <Link to="/" onClick={handleHomeClick}>home</Link> | 
                <Link to="/type/news"> news</Link> |  
                <Link to="/type/thing"> thing</Link> |  
                <Link to="/type/story"> story</Link> | 
                <Link to="/type/eli5"> eli5</Link> |
                <Link to="/type/lols"> lols</Link> |
                <Link to="/type/crypto"> crypto</Link> |
                <Link to="/type/emoji"> 😀</Link> |
                <Link to="/type/geo"> 🌎</Link>
            </nav>
        </div>
    );
}

export default Navigation;
