import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Legend, BarChart, Bar, Cell, LabelList, Tooltip } from 'recharts';

const AuthorInstitutionCard = ({ author, institution }) => {
    const [stats, setStats] = useState(null);
    const [timeSeriesData, setTimeSeriesData] = useState([]);
    const [biasData, setBiasData] = useState([]);
    const [labelInterval, setLabelInterval] = useState(0); // State to manage label interval
    const dBURI = process.env.REACT_APP_DB_API_URL || 'https://haikuthenews-d19750c291e2.herokuapp.com';

    useEffect(() => {
        const fetchStats = async () => {
            const params = { author, institution };
            const queryParams = new URLSearchParams(
                Object.entries(params).filter(([_, v]) => v != null)
            ).toString();
            const res = await fetch(`${dBURI}/stats?${queryParams}`);
            const data = await res.json();
            setStats(data);
            setTimeSeriesData(data.timeSeriesData || []);
            setBiasData(prepareBiasData(data.biasDirectionRatios));
        };
        fetchStats();

        // Update label interval based on screen size
        const updateLabelInterval = () => {
            if (window.innerWidth < 1768) {
                setLabelInterval(2); // Show fewer labels on smaller screens
            } else {
                setLabelInterval(0); // Show all labels on larger screens
            }
        };

        updateLabelInterval();
        window.addEventListener('resize', updateLabelInterval);
        return () => window.removeEventListener('resize', updateLabelInterval);
    }, [author, institution, dBURI]);

    const prepareBiasData = (biasDirectionRatios) => {
        if (!biasDirectionRatios) return [];
    
        const order = ['Left', 'Center-Left', 'Neutral', 'Center', 'None', 'Center-Right', 'Right'];
        return [{
            name: 'Bias',
            ...Object.fromEntries(order.map(direction => [
                direction,
                parseFloat((Number(biasDirectionRatios.find(b => b.direction === direction)?.percentage) || 0).toFixed(1))
            ]))
        }];
    };

    const biasColors = {
        'Left': '#6666FF',
        'Center-Left': '#CCCCFF',
        // 'Neutral': '#CCCC11',
        'Center': '#CCCCCC',
        // 'None': '#CCCC13',
        'Center-Right': '#FFCCCC',
        'Right': '#FF6666',
    };

    if (!stats) return null;

    return (
        <div className="author-institution-card">
            <h2>{author && institution ? `${author} : ${institution}` : author || institution}</h2>
            <table>
                <tbody>
                    <tr>
                        <td>Total Responses:</td>
                        <td>{stats.totalResponses}</td>
                    </tr>
                    <tr>
                        <td>Average Quality Score:</td>
                        <td>{stats.avgQualityScore.toFixed(1)}</td>
                    </tr>
                    <tr>
                        <td>Average Bias Score:</td>
                        <td>{stats.avgBiasScore.toFixed(1)}</td>
                    </tr>
                </tbody>
            </table>
            <div style={{ width: '100%', height: 300 }}>
                <ResponsiveContainer>
                    <LineChart data={timeSeriesData} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis yAxisId="left" />
                        <YAxis yAxisId="right" orientation="right" />
                        <Tooltip formatter={(value) => {
                            const num = Number(value);
                            return num % 1 === 0 ? num.toFixed(0) : num.toFixed(1);
                        }} />
                        <Legend />
                        <Line yAxisId="left" type="monotone" dataKey="qualityScore" stroke="#8884d8" name="Quality Score">
                            <LabelList 
                                dataKey="qualityScore" 
                                position="top" 
                                formatter={(value) => {
                                    const num = Number(value);
                                    return num % 1 === 0 ? num.toFixed(0) : num.toFixed(1);
                                }} 
                                interval={labelInterval} // Use dynamic interval
                            />
                        </Line>
                        <Line yAxisId="right" type="monotone" dataKey="biasScore" stroke="#82ca9d" name="Bias Score">
                            <LabelList 
                                dataKey="biasScore" 
                                position="bottom" 
                                formatter={(value) => {
                                    const num = Number(value);
                                    return num % 1 === 0 ? num.toFixed(0) : num.toFixed(1);
                                }} 
                                interval={labelInterval} // Use dynamic interval
                            />
                        </Line>
                    </LineChart>
                </ResponsiveContainer>
            </div>
            <div style={{ width: '100%', height: 100, marginTop: 20 }}>
                <ResponsiveContainer>
                    {biasData.length > 0 && (
                        <BarChart layout="vertical" data={biasData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis type="number" domain={[0, 100]} hide />
                            <YAxis type="category" dataKey="name" hide />
                            <Legend />
                            {Object.keys(biasColors).map((direction) => (
                                <Bar key={direction} dataKey={direction} stackId="a" fill={biasColors[direction]}>
                                    {biasData[0][direction] > 0 && (
                                        <Cell fill={biasColors[direction]} />
                                    )}
                                    <LabelList
                                        dataKey={direction}
                                        position="center"
                                        formatter={(value) => {
                                            const num = Number(value);
                                            return value > 0 ? `${num % 1 === 0 ? num.toFixed(0) : num.toFixed(1)}%` : '';
                                        }}
                                        fill="#000000"
                                        style={{ fontSize: '12px', fontWeight: 'bold' }}
                                        interval={labelInterval} // Use dynamic interval
                                    />
                                </Bar>
                            ))}
                        </BarChart>
                    )}
                </ResponsiveContainer>
            </div>
        </div>
    );
};

export default AuthorInstitutionCard;
