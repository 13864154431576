import React, { useEffect, useState } from 'react';
import { useUser } from './UserContext';

const User = () => {
    const { generations, moderations, setGenerations, setModerations, guid, username, setUsername, isMod, setIsMod, isUserHidden, setIsUserHidden } = useUser();
    const [newUsername, setNewUsername] = useState('');

    const getCookieValue = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    useEffect(() => {
        const checkCookies = () => {
            const cookieGenerations = getCookieValue('generations');
            const cookieModerations = getCookieValue('moderations');
            const cookieIsMod = getCookieValue('isMod');

            if (cookieGenerations !== null && cookieGenerations !== generations) {
                setGenerations(parseInt(cookieGenerations, 10));
            }
            if (cookieModerations !== null && cookieModerations !== moderations) {
                setModerations(parseInt(cookieModerations, 10));
            }
            if (cookieIsMod !== null) {
                setIsMod(cookieIsMod === 'true');
            }
        };

        checkCookies();
        window.addEventListener('cookieChange', checkCookies);

        return () => {
            window.removeEventListener('cookieChange', checkCookies);
        };
    }, [generations, moderations, setGenerations, setModerations, setIsMod]);

    if (generations === null || moderations === null) return null;

    const deleteAllCookies = () => {
        ['guid', 'generations', 'moderations', 'username'].forEach(cookieName => {
            document.cookie = `${cookieName}=;expires=${new Date(0).toUTCString()};path=/`;
        });
        window.location.reload(); 
    };

    const setUserUsername = async () => {
        if (newUsername) {
            const response = await fetch(`${process.env.REACT_APP_DB_API_URL}/set-username`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ guid, newUsername })
            });
            const data = await response.json();
            // console.log('Server response:', data);
            if (data.success) {
                setUsername(data.username);
                document.cookie = `username=${data.username}; path=/`;
                window.dispatchEvent(new Event('cookieChange'));
                setNewUsername(''); // Clear the input field after successful update
            } else {
                alert('Failed to set or update username. It might already be taken.');
            }
        }
    };

    return (
        <div className={`user-info ${isUserHidden ? 'hidden' : ''}`} style={{ textAlign: 'left' }}>
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%', fontSize: '0.1em'}}>
                <button style={{borderRadius: '10px', cursor: 'not-allowed'}}  className="metal" onClick={deleteAllCookies}>Logout</button>
            </div>
            {username ? (
                <span>username: <a href={`/user/${username}`} target="_blank" rel="noopener noreferrer">{username}</a></span>
            ) : (
                <div>
                    <input 
                        type="text" 
                        value={newUsername} 
                        onChange={(e) => setNewUsername(e.target.value)} 
                        placeholder="Set username"
                    />
                    <button onClick={setUserUsername}>Set Username</button>
                </div>
            )}
            <br></br>
            gens: {generations}
            <br></br>
            mods: {moderations}
            
            <br></br>
            moderate:
            <div className="flipswitch">
                <input type="checkbox" name="flipswitch"  className="flipswitch-cb metal" id="fs" defaultChecked={isMod} onChange={() => setIsMod(!isMod)} />
                <label className="flipswitch-label" htmlFor="fs">
                    <div style={{cursor: 'ew-resize'}} className="flipswitch-inner"></div>
                    <div style={{cursor: 'ew-resize'}} className="flipswitch-switch"></div>
                </label>
            </div>
            <br></br>
            <button style={{cursor: 'ns-resize'}} onClick={() => setIsUserHidden(!isUserHidden)} className="hide-button">
                {isUserHidden ? 'Show User' : 'Hide User'}
            </button>
        </div>
    );
};

export default User;