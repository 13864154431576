import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

const calculateDaysSince = (createdAt) => {
    const createdDate = new Date(createdAt);
    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - createdDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
};

const ResponseInfoMain = ({ response, isSingleResponse, isFilteredResponse }) => {
    const [daysSince, setDaysSince] = useState(0);

    useEffect(() => {
        setDaysSince(calculateDaysSince(response.createdAt));
    }, [response.createdAt]);

    return (
        <div className={`response-info-main ${isSingleResponse ? 'response-info-single' : ''} ${isFilteredResponse ? 'response-info-filtered' : ''}    `}>
            <div className={`response-info-main-table ${isSingleResponse ? 'response-info-main-table-single' : ''}`}>
                <table>
                    <tbody>
                        <tr>
                            <td>
                                <Link to={`/response/${response.createdAt}`}>
                                    gen: {new Date(response.createdAt).toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' }).replace(',', ' :').replace(/(\d{2})\/(\d{2})\/(\d{4})/,'$3/$2/$1').trim()} in {response.duration < 60 ? `${response.duration.toFixed(1)} sec` : `${Math.floor(response.duration / 60)} min ${(response.duration % 60).toFixed(1)} sec`}                         
                                    {(response.cryptoPredictionResult || response.type === 'news') && daysSince > 0 && daysSince <= 7 ? ` (-${daysSince}d)` : (response.cryptoPredictionResult || response.type === 'news') && daysSince > 7 ? ' (old)' : ''}
                                </Link>
                                {response.walterCronkiteResult && (
                                    <span style={{ float: 'right', textAlign: 'right' }}>
                                        bias: {response.walterCronkiteResult.Bias_Score} ({response.walterCronkiteResult.Bias_Direction})
                                    </span>
                                )}
                                {response.geoResult && (
                                    <span style={{ float: 'right', textAlign: 'right' }}>
                                        🌎: <a href={`https://www.google.com/maps?ll=${response.geoResult.lat_long}&spn=1,1&t=k`} target="_blank" rel="noopener noreferrer">
                                            {typeof response.geoResult.lat_long === 'string' ? response.geoResult.lat_long.split(/,\s*/).map(coord => parseFloat(coord).toFixed(2)).join(', ') : 'Invalid coordinates'}
                                        </a>
                                    </span>
                                )}
                                {response.cryptoPredictionResult && (
                                    <span style={{ float: 'right', textAlign: 'right' }}>
                                        {response.cryptoPredictionResult.crypto_name} ({response.cryptoPredictionResult.crypto_abbr})
                                    </span>
                                )}
                                {response.thingResult && (
                                    <span style={{ float: 'right', textAlign: 'right' }}>
                                        name: {response.thingResult.common_name}
                                    </span>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Link to={`/type/${response.type}`}>
                                    type: {response.type}
                                </Link>
                                {response.thingResult && (
                                    <span style={{ float: 'right', textAlign: 'right' }}>
                                        scientific name: {response.thingResult.scientific_name}
                                    </span>
                                )}
                                {response.walterCronkiteResult && (
                                    <span style={{ float: 'right', textAlign: 'right' }}>
                                        quality: {response.walterCronkiteResult.Quality_Score}
                                    </span>
                                )}
                                {response.geoResult && (
                                    <span style={{ float: 'right', textAlign: 'right' }}>
                                        name: {response.geoResult.location}
                                    </span>
                                )}
                                {response.cryptoPredictionResult && (
                                    <span style={{ float: 'right', textAlign: 'right' }}>
                                        prediction: {response.cryptoPredictionResult.target_price_range}
                                    </span>
                                )}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                pts: {response.rank}
                                {response.thingResult && (
                                    <span style={{ float: 'right', textAlign: 'right' }}>
                                        confidence level: {response.thingResult.confidence}%
                                    </span>
                                )}
                                {response.cryptoPredictionResult && (
                                    <span style={{ float: 'right', textAlign: 'right' }}>
                                        confidence level: {response.cryptoPredictionResult.confidence_level}
                                    </span>
                                )}
                                {response.geoResult && (
                                    <span style={{ float: 'right', textAlign: 'right' }}>
                                        confidence level: {response.geoResult.confidence_level}%
                                    </span>
                                )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ResponseInfoMain;
