import React from 'react';

const Pagination = ({ page, setPage }) => {
    return (
        <div className="pagination">
            <button onClick={() => { setPage(1); window.scrollTo(0, 0); }} style={{ display: page === 1 || page === 2 ? 'none' : 'inline' }}>{"<<<"}</button>
            <button onClick={() => { setPage(page - 1); window.scrollTo(0, 0); }} style={{ display: page === 1 ? 'none' : 'inline' }}>prev</button>
            <button onClick={() => { setPage(page + 1); window.scrollTo(0, 0); }}>next</button>
        </div>
    );
};

export default Pagination;
