import React, { useState, useEffect } from 'react';

const AudioControls = ({ currentAudio, setCurrentAudio, isPaused, setIsPaused, currentTime, setCurrentTime, duration, setDuration, showControls, setShowControls }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [playbackSpeed, setPlaybackSpeed] = useState(5); // Added this line

    const togglePauseResume = () => {
        if (currentAudio) {
            if (isPaused) {
                currentAudio.play();
            } else {
                currentAudio.pause();
            }
            setIsPaused(!isPaused);
            currentAudio.playbackRate = playbackSpeed / 5; // Adjust for 1-10 scale
        }
    };

    useEffect(() => {
        if (currentAudio) {
            const handlePause = () => setIsPaused(true);
            const handlePlay = () => setIsPaused(false);

            currentAudio.addEventListener('pause', handlePause);
            currentAudio.addEventListener('play', handlePlay);

            return () => {
                currentAudio.removeEventListener('pause', handlePause);
                currentAudio.removeEventListener('play', handlePlay);
            };
        }
    }, [currentAudio]);

    const handleMouseDown = () => setIsDragging(true);
    const handleMouseUp = () => setIsDragging(false);
    const handleMouseMove = (e) => {
        if (isDragging && currentAudio) {
            const progressBar = document.querySelector('.progress-bar');
            const rect = progressBar.getBoundingClientRect();
            const offsetX = e.clientX - rect.left;
            const newTime = (offsetX / rect.width) * duration;
            currentAudio.currentTime = newTime;
            setCurrentTime(newTime);
        }
    };

    const handleTouchStart = () => setIsDragging(true);
    const handleTouchEnd = () => setIsDragging(false);
    const handleTouchMove = (e) => {
        if (isDragging && currentAudio) {
            const progressBar = document.querySelector('.progress-bar');
            const rect = progressBar.getBoundingClientRect();
            const offsetX = e.touches[0].clientX - rect.left;
            const newTime = (offsetX / rect.width) * duration;
            currentAudio.currentTime = newTime;
            setCurrentTime(newTime);
        }
    };

    useEffect(() => {
        if (currentAudio) {
            const handleAudioEnd = () => {
                setTimeout(() => {
                    setShowControls(false);
                    setCurrentAudio(null);
                    setIsPaused(false);
                    setCurrentTime(0);
                }, 2232); 
            };

            currentAudio.addEventListener('ended', handleAudioEnd);

            return () => {
                currentAudio.removeEventListener('ended', handleAudioEnd);
            };
        }
    }, [currentAudio]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (!showControls) return;

            switch (e.key) {
                case ' ':
                    e.preventDefault();
                    togglePauseResume();
                    break;
                case 'ArrowUp':
                    setPlaybackSpeed((prev) => Math.min(prev + 1, 15));
                    if (currentAudio) currentAudio.playbackRate = (playbackSpeed + 1) / 5;
                    break;
                case 'ArrowDown':
                    setPlaybackSpeed((prev) => Math.max(prev - 1, 1));
                    if (currentAudio) currentAudio.playbackRate = (playbackSpeed - 1) / 5;
                    break;
                case 'ArrowRight':
                    if (currentAudio) {
                        const newTime = Math.min(currentAudio.currentTime + 10, duration);
                        currentAudio.currentTime = newTime;
                        setCurrentTime(newTime);
                    }
                    break;
                case 'ArrowLeft':
                    if (currentAudio) {
                        const newTime = Math.max(currentAudio.currentTime - 10, 0);
                        currentAudio.currentTime = newTime;
                        setCurrentTime(newTime);
                    }
                    break;
                case 'Escape':
                    if (currentAudio) {
                        currentAudio.pause();
                        currentAudio.currentTime = 0;
                        currentAudio.playbackRate = 1.0; // Reset speed to 1.0 when stopped
                        setCurrentAudio(null);
                        setIsPaused(false);
                        setShowControls(false);
                    }
                    break;
                default:
                    break;
            }
        };

        window.addEventListener('keydown', handleKeyDown);
        return () => window.removeEventListener('keydown', handleKeyDown);
    }, [showControls, currentAudio, playbackSpeed, duration, currentTime]);

    useEffect(() => {
        if (!showControls) {
            setPlaybackSpeed(5); // Reset to default speed (5 corresponds to 1.0x)
            if (currentAudio) {
                currentAudio.playbackRate = 1.0;
            }
        }
    }, [showControls]);

    return (
        <>
            {showControls && currentAudio && (
                <div className="progress-bar" 
                     onMouseMove={handleMouseMove} 
                     onMouseUp={handleMouseUp} 
                     onTouchMove={handleTouchMove} 
                     onTouchEnd={handleTouchEnd}>
                    <div className="progress" style={{ width: `${(currentTime / duration) * 100}%` }}>
                        <div className="progress-icon" 
                             onMouseDown={handleMouseDown} 
                             onTouchStart={handleTouchStart} 
                             style={{ left: `${(currentTime / duration) * 100}%` }}>
                            🎤
                        </div>
                    </div>
                </div>
            )}
            {showControls && (
                <>
                    <button className="pause-resume-button" onClick={togglePauseResume}>
                        {isPaused ? 'cook' : 'hol\'up'}
                    </button>
                    <button className="stop-button" onClick={() => {
                        if (currentAudio) {
                            currentAudio.pause();
                            currentAudio.currentTime = 0;
                            currentAudio.playbackRate = 1.0; // Reset speed to 1.0 when stopped
                            setCurrentAudio(null);
                            setIsPaused(false);
                            setShowControls(false);
                        }
                    }}>shush</button>
                    <div className="speed-control">
                        <label htmlFor="speed">Speed: </label>
                        <input className="speed-slider"
                            type="range"
                            id="speed"
                            min="1"
                            max="15"
                            step="1"
                            value={playbackSpeed}
                            list="tickmarks"
                            onChange={(e) => {
                                setPlaybackSpeed(e.target.value);
                                if (currentAudio) {
                                    currentAudio.playbackRate = e.target.value / 5; // Adjust for 1-10 scale
                                }
                            }}
                            style={{ width: '340px', height: '22px'}} // Make the slider bigger
                        />
                        <datalist id="tickmarks">
                            <option value="1" label="0.2x"></option>
                            <option value="2" label="0.4x"></option>
                            <option value="3" label="0.6x"></option>
                            <option value="4" label="0.8x"></option>
                            <option value="5" label="1x"></option>
                            <option value="6" label="1.2x"></option>
                            <option value="7" label="1.4x"></option>
                            <option value="8" label="1.6x"></option>
                            <option value="9" label="1.8x"></option>
                            <option value="10" label="2x"></option>
                            <option value="11" label="2.5x"></option>
                            <option value="12" label="3.0x"></option>
                            <option value="13" label="2.6x"></option>
                            <option value="14" label="2.8x"></option>
                            <option value="15" label="3x"></option>
                        </datalist>
                        <div className="speed-display">{(playbackSpeed / 5).toFixed(1)}x</div>
                    </div>
                </>
            )}
        </>
    );
};

export default AudioControls;
