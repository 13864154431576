import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './App.css';
import Navigation from './components/Navigation'; 
import WalterCronkiteResult from './components/WalterCronkiteResult';
import AudioControls from './components/AudioControls';
import ResponseText from './components/ResponseText';
import User from './components/User';
import { useUser } from './components/UserContext';
import ResponseInfoMain from './components/ResponseInfoMain';
import Comments from './components/Comments';

const getCookieValue = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

function SingleResponse() {
    const { createdAt } = useParams();
    const [response, setResponse] = useState(null);
    const navigate = useNavigate();
    const [voteCast, setVoteCast] = useState(false)
    const [loading, setLoading] = useState(true);
    const [currentAudio, setCurrentAudio] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [showControls, setShowControls] = useState(false);
    const { setModerations } = useUser();
    const { user, isMod, isUserHidden } = useUser(); 
    const [playbackSpeed] = useState(1); 
    const dbURI = process.env.REACT_APP_DB_API_URL || 'http://192.168.0.83:3001';
    const s3URI = process.env.REACT_APP_S3_URI || 'https://haikuthenews-responses.s3.amazonaws.com';

    useEffect(() => {
        const fetchResponse = async () => {
            const res = await fetch(`${dbURI}/response/${createdAt}`);
            const data = await res.json();
            setResponse(data);
            setLoading(false);
        };
        fetchResponse();

    }, [createdAt, dbURI]);

    if (loading) {
        return <div><div><span style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}><p>Loading...patience...these things take time...</p></span></div><div class="custom-loader" style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}></div></div>
    }

    const vote = async (responseId, direction) => {
        const guid = getCookieValue('guid'); // Retrieve guid from cookies
        const response = await fetch(`${dbURI}/vote/${responseId}/${direction}`, { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ guid })
        });
        const data = await response.json();
        if (data.success) {
            document.cookie = `moderations=${data.moderations}; path=/`;
            setModerations(data.moderations); // Update state
            window.dispatchEvent(new Event('cookieChange'));
        }
        const fetchResponse = async () => {
            const res = await fetch(`${dbURI}/response/${createdAt}`);
            const data = await res.json();
            setResponse(data);
        };
        setVoteCast(true); // Set voteCast to true when a vote is cast
        fetchResponse();
    };

    const handleAudioPlay = (audio) => {
        if (currentAudio && currentAudio !== audio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
        }
        setCurrentAudio(audio);
        setIsPaused(false);
        setDuration(audio.duration);
        setShowControls(true);
        audio.ontimeupdate = () => setCurrentTime(audio.currentTime);
        audio.playbackRate = playbackSpeed; 
    };
    
    return (
        <div>
            <header className="App-header-single">
            <Navigation isUserHidden={isUserHidden} user={user} />
            <User />
            <div className={`response-card-single ${response.type}`}>
                <ResponseInfoMain response={response} isSingleResponse={true} />
                <ResponseText response={response} index={response._id} handleAudioPlay={handleAudioPlay} single={true} />
                <div className='generated-image-single'>
                    {response.generatedImage && (
                        <a href={response.generatedImage.replace('.png', '.png_lrg.jpg')} target="_blank" rel="noopener noreferrer">
                            <img src={response.generatedImage.replace('.png', '.png_lrg.jpg')} alt="Generated" style={{ width: '100%', height: 'auto' }}/>
                        </a>
                    )}
                </div>
                <div className='walter-cronkite-table-wrapper-single'>
                    {response.walterCronkiteResult ? (
                       <WalterCronkiteResult response={response} />
                    ) : (
                        <div className='uploaded_image'>
                            <br />
                            {response.url ? (
                                <div className='uploaded-image-url'>
                                    <a href={response.url} target="_blank" rel="noopener noreferrer">
                                        <img src={`${s3URI}/${response.screenshot}`} alt={`Holder`} />
                                    </a>
                                </div>
                            ) : (
                                <div className='uploaded-image-image-single'>
                                    <a href={`${s3URI}/${response.screenshot}`} target="_blank" rel="noopener noreferrer">
                                        <img src={`${s3URI}/${response.screenshot}`} alt={`Holder`} />
                                    </a>
                                </div>
                            )}
                        </div>
                    )}
                </div>
                {user && isMod && ( 
                    <>
                        <button className="up-button" onClick={() => vote(response._id, 'up')}></button>
                        <button className="down-button" onClick={() => vote(response._id, 'down')}></button>
                    </>
                )} 
                <button className="full-width-button" onClick={() => {
                    navigate('/');
                    if (voteCast) {
                        window.location.reload(); // Trigger a refresh if a vote was cast
                    }
                }}>
                    Back
                </button>
            </div>
            <AudioControls
                            currentAudio={currentAudio}
                            setCurrentAudio={setCurrentAudio}
                            isPaused={isPaused}
                            setIsPaused={setIsPaused}
                            currentTime={currentTime}
                            setCurrentTime={setCurrentTime}
                            duration={duration}
                            setDuration={setDuration}
                            showControls={showControls}
                            setShowControls={setShowControls}
                            handleAudioPlay={handleAudioPlay}
                        />
            <Comments responseId={response._id} />
        </header>
        </div>
        
    );
}

export default SingleResponse;

