import React from 'react';

const ResponseText = ({ response, index, handleAudioPlay, single, filtered }) => {
    let text = response.result; // Default text
    const dbURI = process.env.REACT_APP_DB_API_URL || 'http://192.168.0.83:3001';
    const s3URI = process.env.REACT_APP_S3_URI || 'https://haikuthenews-d19750c291e2.herokuapp.com';
    if (response.type === 'crypto' && response.cryptoPredictionResult) {
        text = response.cryptoPredictionResult.crypto_name + "\n\nTicker: (" + response.cryptoPredictionResult.crypto_abbr + ")\n\n" + response.cryptoPredictionResult.explanation;
    } else if (response.type === 'emoji') {
        text = response.emojiResult ? response.emojiResult.emoji_description : "No emoji description available.";
    } else if (response.type === 'geo') {
        text = response.geoResult ? response.geoResult.location : "No location description available.";
    } else if (response.type === 'thing') {
        text = response.thingResult ? "It is a " + response.thingResult.common_name + ".\n\nScientific name: " + response.thingResult.scientific_name + ".\n\n" + response.thingResult.description   + ".\n\n" + response.thingResult.interesting_fact + ".\n\n" + response.thingResult.reasoning : "No description available.";
    }

    // Ensure text is not undefined
    text = text || "No description available.";

    // Determine the class based on response type
    let responseClass = single ? 'response-text-single' : filtered ? 'response-text-filtered' : 'response-text';
    const typeClass = response.type ? `response-text-${response.type}` : '';
    if (responseClass === 'response-text-filtered') {
        responseClass = 'response-text-filtered' + '-' + response.type;
    }

    const handleTextClick = (audioElement) => {
        if (audioElement.paused) {
            audioElement.play();
        } else {
            audioElement.pause();
        }
        handleAudioPlay(audioElement);
    };

    // Calculate shadow based on latitude and longitude
    const shadowStyle = {};
    if (response.type === 'geo' && response.geoResult) {
        const { lat_long } = response.geoResult;
        if (typeof lat_long === 'string') {
            const [lat, long] = lat_long.split(',').map(coord => parseFloat(coord.trim()));
            const newlat = -lat / 2;
            const newlong = long / 5;
            shadowStyle.boxShadow = `${newlat}px ${newlong}px 20px rgba(0, 0, 0, 0.5)`;
            shadowStyle['--hover-box-shadow'] = `${newlat}px ${newlong}px 20px rgba(0, 0, 0, 0.5)`;
        } else {
            console.error("lat_long is not a string:", lat_long);
        }
    }

    return (
        <div className={`${responseClass} ${typeClass}`} >
            <pre className={text.length > 300 ? 'long-text' : ''} style={shadowStyle} onClick={() => handleTextClick(document.getElementById(`audio-${index}`))}>{text}</pre>
            {response.generatedVoice && (
                <audio id={`audio-${index}`} src={`${s3URI}/${response.generatedVoice}`} onPlay={(e) => handleAudioPlay(e.target)} />
            )}
        </div>
    );
};

export default ResponseText;
