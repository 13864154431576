import React, { useState, useEffect, useRef } from 'react';
import { useUser } from './UserContext';

const CommentItem = ({ comment, handleVote, handleReply, handleEmotion, depth = 0 }) => {
  const [showReplyForm, setShowReplyForm] = useState(false);
  const [replyContent, setReplyContent] = useState('');
  const [showReplies, setShowReplies] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { user, isMod } = useUser();
  const repliesRef = useRef(null);

  const [showEmotionPicker, setShowEmotionPicker] = useState(false);

  const emotions = [
    { name: 'Interest', color: 'orange', emoji: '🤔' },
    { name: 'Anger', color: 'red', emoji: '😠' },
    { name: 'Boring', color: 'rgb(74, 65, 42,0.8)', emoji: '😴' },
    { name: 'Sad', color: 'rgb(205, 115, 180, 0.85)', emoji: '😔' },
    { name: 'Amazed', color: 'rgba(10, 106, 202, 0.941)', emoji: '🥹' },
    { name: 'Scary', color: 'darkblue', emoji: '😱' },
    { name: 'Trusting', color: 'green', emoji: '🤝' },
    { name: 'Joy', color: 'yellow', emoji: '😂' }
  ];

  const emotionPickerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emotionPickerRef.current && !emotionPickerRef.current.contains(event.target)) {
        setShowEmotionPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (!showReplies && isClosing) {
      const timer = setTimeout(() => {
        setIsClosing(false);
      }, 500); // This should match the transition duration in CSS
      return () => clearTimeout(timer);
    }
  }, [showReplies, isClosing]);

  const onReplySubmit = (e) => {
    e.preventDefault();
    handleReply(comment._id, replyContent);
    setReplyContent('');
    setShowReplyForm(false);
  };

  const toggleReplies = () => {
    if (showReplies) {
      setIsClosing(true);
    }
    setShowReplies(!showReplies);
  };

  const toggleEmotionPicker = () => {
    if (!showEmotionPicker) {
      setShowEmotionPicker(true);
      setTimeout(() => {
        const buttons = document.querySelectorAll('.emotion-button');
        buttons.forEach((button, index) => {
          setTimeout(() => {
            button.classList.add('open');
          }, index * 50);
        });
      }, 0);
    } else {
      const buttons = document.querySelectorAll('.emotion-button');
      buttons.forEach((button, index) => {
        setTimeout(() => {
          button.classList.remove('open');
        }, index * 50);
      });
      setTimeout(() => {
        setShowEmotionPicker(false);
      }, buttons.length * 50);
    }
  };

  const selectEmotion = (emotion) => {
    handleEmotion(comment._id, emotion);
    setShowEmotionPicker(false);
  };

  const createGradient = (emotionCounts, type = 'background') => {
    if (!emotionCounts || (typeof emotionCounts !== 'object' && !(emotionCounts instanceof Map))) return 'white';

    let entries;
    if (emotionCounts instanceof Map) {
      entries = Array.from(emotionCounts.entries());
    } else {
      entries = Object.entries(emotionCounts);
    }

    if (entries.length === 0) return 'white';

    // If there's only one emotion, return a solid color
    if ((entries.length === 1) && (type === 'backgroundColor')) {
      const [emotion] = entries[0];
      const color = emotions.find(e => e.name === emotion)?.color || 'gray';
      // console.log('color', color);
      return color;
    }

    const sortedEmotions = entries.sort((a, b) => b[1] - a[1]);

    // const total = sortedEmotions.reduce((sum, [_, count]) => sum + count, 0);
    let gradientString = type === 'background' ? 'linear-gradient(to right' : 'linear-gradient(to left';
    // let currentPercentage = 0;
    let boxShadowString = '';
    let dropShadowString = '';

    sortedEmotions.forEach(([emotion, count], index) => {
      // const percentage = (count / total) * 100;
      const color = emotions.find(e => e.name === emotion)?.color || 'gray';
      // gradientString += `, ${color} ${currentPercentage}% ${currentPercentage + percentage}%`;
      gradientString += `, ${color}`;
      // gradientString += index === 0 ? `${color}` : `, ${color}`;

      const shadowIntensity = Math.min(10, count * 1.5);
      const doubleShadowIntensity = shadowIntensity * 1.5;
      const tripleShadowIntensity = shadowIntensity * 2;
      if ((type === 'dropShadow') && (index === 0)) {
        dropShadowString = `${color} ${shadowIntensity}px ${doubleShadowIntensity}px ${tripleShadowIntensity}px`;
      } else if ((type === 'boxShadow') && (index === 0)) {
        boxShadowString = `${shadowIntensity}px ${doubleShadowIntensity}px ${tripleShadowIntensity}px ${doubleShadowIntensity}px ${color}`;
      }
    });
    
    gradientString += ')';
    return type === 'boxShadow' ? boxShadowString : (type === 'dropShadow' ? dropShadowString : gradientString);
  };

  const commentStyle = {
    backgroundImage: createGradient(comment.emotionCounts, 'background'),
    backgroundColor: comment.emotionCounts && Object.keys(comment.emotionCounts).length === 1 ? createGradient(comment.emotionCounts, 'backgroundColor') : 'white',
    // borderImage: createGradient(comment.emotionCounts, 'border'),
    // borderImageWidth: 'auto',
    // borderStyle: 'groove',
    // borderWidth: '2px',
    borderRadius: '8px',
  };

  const commentContentStyle = {
    // boxShadow: createGradient(comment.emotionCounts, 'boxShadow'),
    filter: `drop-shadow(${createGradient(comment.emotionCounts, 'dropShadow')})`
  };

  // console.log('commentStyle', commentStyle);
  // console.log('commentContentStyle', commentContentStyle);

  return (
    <div className="comment-container"
      style={{ 
        zIndex: comment.ratings && comment.ratings.importance !== undefined ? 
          `${comment.ratings.importance > 0 ? comment.ratings.importance : 0}` : '0', 
        transform: comment.ratings && comment.ratings.bias !== undefined && comment.ratings.importance !== undefined ?
          `translateX(${comment.ratings.bias}%)` :
          'none',
        marginLeft: `${depth * 5}px`,
        ...commentStyle
      }}
      onMouseEnter={(e) => {
        if (comment.ratings && comment.ratings.importance !== undefined) {
          e.currentTarget.style.zIndex = '500';
        }
      }}
      onMouseLeave={(e) => {
        if (comment.ratings && comment.ratings.importance !== undefined) {
          e.currentTarget.style.zIndex = `${comment.ratings.importance > 0 ? comment.ratings.importance : 0}`;
        }
      }}
    > 
      <div className="comment" id={comment._id} style={{
        ...commentContentStyle,
        transform: comment.ratings && comment.ratings.bias !== undefined && comment.ratings.importance !== undefined ?
          `translateX(${comment.ratings.bias}%) scale(${comment.ratings.importance > 0 ? 1 + comment.ratings.importance * 0.1 : Math.max(0.5, 1 + comment.ratings.importance * 0.1)})` :
          'none'
      }}>
      {user && isMod && (
        <>
          <button className="quality-arrow uptriangle" onClick={() => handleVote(comment._id, 'quality', 'up')}></button>
          <button className="bias-arrow left lefttriangle" onClick={() => handleVote(comment._id, 'bias', 'left')}></button>
          <button className="bias-arrow right righttriangle" onClick={() => handleVote(comment._id, 'bias', 'right')}></button>
          <button className="quality-arrow down downtriangle" onClick={() => handleVote(comment._id, 'quality', 'down')}></button>
        </>
      )} 
        <div className="comment_header">
          <span><small>{new Date(comment.createdAt).toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' }).replace(',', ' :').replace(/(\d{2})\/(\d{2})\/(\d{4})/,'$3/$2/$1').trim()}</small></span>
          {user && isMod && (
            <span onClick={() => handleVote(comment._id, 'importance', 'more')} style={{cursor: 'zoom-in'}}><small>⭐</small></span>
          )}
        </div>
        <p><strong>{comment.username}</strong>: {comment.content}</p>
        {user && isMod && (
          <div className="ratings">
            <span onClick={() => handleVote(comment._id, 'importance', 'less')} style={{cursor: 'not-allowed'}}><span>🗑️</span></span>
            <span>Bias: {comment.ratings?.bias || 0}</span>
            <span>Quality: {comment.ratings?.quality || 0}</span>
            <span>Popularity: {comment.ratings?.importance || 0}</span>
            <span onClick={toggleEmotionPicker} style={{cursor: 'pointer', position: 'relative'}}>
              <div className="emotion-picker-container" style={{opacity: showEmotionPicker ? 1 : 0}}>
                {emotions.map((emotion, index) => (
                  <button
                    key={emotion.name}
                    onClick={() => selectEmotion(emotion.name)}
                    title={emotion.name}
                    className={`emotion-button ball`}
                    style={{
                      backgroundColor: emotion.color,
                      boxShadow: `0 0 12px ${emotion.color}`,
                      border: `1px solid black`,
                         color: 'white',
                      '--rotation': `${index * 45}deg`,
                    }}
                  >
                    <span className="ball-content">{emotion.emoji}</span>
                  </button>
                ))}
              </div>
              <span role="img" aria-label="Emotion">☸️</span>
            </span>
          </div>
        )}
        <div className="comment-actions">
          {user && (
            <button onClick={() => setShowReplyForm(!showReplyForm)}>reply</button>
          )}
          {comment.replies && comment.replies.length > 0 && (
            <button onClick={toggleReplies}>
              {showReplies ? 'hide replies' : `show replies (${comment.replies.length})`}
            </button>
          )}
        </div>
        {showReplyForm && (
          <form onSubmit={onReplySubmit}>
            <textarea
              value={replyContent}
              onChange={(e) => setReplyContent(e.target.value)}
              className="reply-reply-textarea"
              placeholder="Reply to the reply..."
            />
            <button className="reply-reply-button" type="submit">submit</button>
          </form>
        )}
      </div>
      <div 
        className={`comment-replies ${showReplies ? 'show' : ''} ${isClosing ? 'hide' : ''}`}
        ref={repliesRef}
        // style={{ transform: 'scale(1)' }} 
      >
        {(showReplies || isClosing) && comment.replies && comment.replies.map((reply, index) => (
          <CommentItem 
            key={`${comment._id}-${reply._id || index}`}
            comment={{...reply, emotion: reply.emotion}}
            handleVote={handleVote} 
            handleReply={handleReply}
            handleEmotion={handleEmotion}
            depth={depth + 1}
          />
        ))}
      </div>
    
    </div>
  );
};

export default CommentItem;