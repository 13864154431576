import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import './App.css';
import Navigation from './components/Navigation'; 
import WalterCronkiteResult from './components/WalterCronkiteResult';
import AudioControls from './components/AudioControls';
import Footer from './components/Footer';
import AuthorInstitutionCard from './components/AuthorInstitutionCard';
import ResponseText from './components/ResponseText';
import ResponseInfoMain from './components/ResponseInfoMain';
import { useUser } from './components/UserContext';
import User from './components/User';

function FilteredResponses({ username }) {
    const { type, author, institution } = useParams();
    const { user, isMod, isUserHidden } = useUser();  
    const [responses, setResponses] = useState([]);
    const [voteCast, setVoteCast] = useState(false);
    const navigate = useNavigate();

    const [currentAudio, setCurrentAudio] = useState(null);
    const [isPaused, setIsPaused] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [showControls, setShowControls] = useState(false);
    const [page, setPage] = useState(1);
    const { setModerations } = useUser();
    const [playbackSpeed] = useState(1); 
    const dbURI = process.env.REACT_APP_DB_API_URL || 'http://192.168.0.83:3001';
    const s3URI = process.env.REACT_APP_S3_URI || 'https://haikuthenews-responses.s3.amazonaws.com';
    const [showMore, setShowMore] = useState(false); 



    const handleAudioPlay = (audio) => {
        if (currentAudio && currentAudio !== audio) {
            currentAudio.pause();
            currentAudio.currentTime = 0;
        }
        setCurrentAudio(audio);
        setIsPaused(false);
        setDuration(audio.duration);
        setShowControls(true);
        audio.ontimeupdate = () => setCurrentTime(audio.currentTime);
        audio.playbackRate = playbackSpeed; // Add this line
    };

    const getCookieValue = (name) => {
        const value = `; ${document.cookie}`;
        const parts = value.split(`; ${name}=`);
        if (parts.length === 2) return parts.pop().split(';').shift();
        return null;
    };

    useEffect(() => {
        const fetchResponses = async () => {
            const params = { type, author, institution, username, page, limit: 12 };
            const queryParams = new URLSearchParams(
                Object.entries(params).filter(([_, v]) => v != null)
            ).toString();

            // console.log("Query Params: ", queryParams);
            const res = await fetch(`${dbURI}/responses?${queryParams}`);
            const data = await res.json();
            setResponses(data);
        };
        fetchResponses();
    }, [type, author, institution, username, page, dbURI]);

    const [commentCounts, setCommentCounts] = useState({});

    useEffect(() => {
      const fetchCommentCounts = async () => {
        const counts = {};
        for (const response of responses) {
          const res = await fetch(`${dbURI}/comment-count/${response._id}`);
          const data = await res.json();
          counts[response._id] = data.count;
        }
        setCommentCounts(counts);
      };

      if (responses.length > 0) {
        fetchCommentCounts();
      }
    }, [responses]);

    const vote = async (responseId, direction) => {
        const guid = getCookieValue('guid'); // Retrieve guid from cookies
        const response = await fetch(`${dbURI}/vote/${responseId}/${direction}`, { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ guid })
        });
        const data = await response.json();
        if (data.success) {
            document.cookie = `moderations=${data.moderations}; path=/`;
            setModerations(data.moderations); // Update state
            window.dispatchEvent(new Event('cookieChange'));
        }const fetchResponses = async () => {
            const res = await fetch(`${dbURI}/responses?type=${type}`);
            const data = await res.json();
            setResponses(data);
        };
        setVoteCast(true); // Set voteCast to true when a vote is cast
        fetchResponses();
    };

    return (
        <div>
            <Navigation isUserHidden={isUserHidden} user={user} />
            <User />
            {author || institution ? (
                <AuthorInstitutionCard author={author} institution={institution} />
            ) : null}
            <div className="responses-filtered">
                {responses.length > 0 ? (
                    responses.map((response, index) => (
                        <div key={index} className={`response-card ${response.type}`}>
                            <ResponseInfoMain response={response} isFilteredResponse={true}  />
                            <ResponseText response={response} index={index} handleAudioPlay={handleAudioPlay} filtered={true} />
                            <div className='generated-image'>
                                {response.generatedImage && (
                                    <a href={response.generatedImage.replace('.png', '.png_lrg.jpg')} target="_blank" rel="noopener noreferrer">
                                    <img src={response.generatedImage.replace('.png', '.png_sml.jpg')} alt="Generated" />
                                </a>
                                )}
                            </div>
                            <div className={`walter-cronkite-table-wrapper-filtered ${showMore ? 'show' : 'hide'}`}>
                                {response.walterCronkiteResult ? (
                                    <WalterCronkiteResult response={response} />
                                ) : (
                                    <div className='uploaded_image'>
                                        <br />
                                        {response.url ? (
                                            <div className='uploaded-image-url'>
                                                <a href={response.url} target="_blank" rel="noopener noreferrer">
                                                    <img src={`${s3URI}/${response.screenshot}`} alt={`Holder`} />
                                                </a>
                                            </div>
                                        ) : (
                                            <div className='uploaded-image-image-filtered'>
                                                <a href={`${s3URI}/${response.screenshot}`} target="_blank" rel="noopener noreferrer">
                                                    <img src={`${s3URI}/${response.screenshot}`} alt={`Holder`} />
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                            <div className="expand-main-container" style={{ textAlign: 'center', fontFamily: 'monospace' }} >
                                            <div style={{position: 'relative', bottom: '5px', right: '5px', textAlign: 'center'}} onClick={() => setShowMore(!showMore)}>
                                                <span className='expand-button'>
                                                    {showMore ? '^^ less ^^' : '∨∨ more ∨∨'}
                                                </span>
                                            </div>          
                                            <div style={{ position: 'relative', bottom: '5px', right: '5px', textAlign: 'right'}}>
                                                <span className="metal linear">
                                                    <Link to={`/response/${response.createdAt}`}>
                                                        {commentCounts[response._id] ? `comments (${commentCounts[response._id]})` : 'comment?'}
                                                    </Link>   
                                                </span>
                                            </div>
                            </div>
                            {user && isMod && ( 
                                <>
                                    <button className="up-button" onClick={() => vote(response._id, 'up')}></button>
                                    <button className="down-button" onClick={() => vote(response._id, 'down')}></button>
                                </>
                            )} 
                            <button className="full-width-button" onClick={() => {
                                navigate('/');
                                if (voteCast) {
                                    window.location.reload(); // Trigger a refresh if a vote was cast
                                }
                            }}>
                                Back
                            </button>
                        </div>
                    ))
                ) : (
                    <div>
                        <div><span style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}><p>No responses found, yet...but I assure you, we're trying.</p></span></div>
                        <div class="custom-loader" style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}></div>
                    </div>
                )}
            </div>
            <Footer page={page} setPage={setPage} isFiltered={true} />
            <AudioControls
                            currentAudio={currentAudio}
                            setCurrentAudio={setCurrentAudio}
                            isPaused={isPaused}
                            setIsPaused={setIsPaused}
                            currentTime={currentTime}
                            setCurrentTime={setCurrentTime}
                            duration={duration}
                            setDuration={setDuration}
                            showControls={showControls}
                            setShowControls={setShowControls}
                            handleAudioPlay={handleAudioPlay}
            />
        </div>
    );
}

export default FilteredResponses;