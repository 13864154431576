import React, { createContext, useState, useContext, useEffect } from 'react';

const UserContext = createContext();

const getCookieValue = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
};

export const UserProvider = ({ children }) => {
    const [generations, setGenerations] = useState(getCookieValue('generations'));
    const [moderations, setModerations] = useState(getCookieValue('moderations'));
    const [guid, setGuid] = useState(getCookieValue('guid'));
    const [username, setUsername] = useState(getCookieValue('username'));
    const [isMod, setIsMod] = useState(getCookieValue('isMod') === 'true');
    const [isUserHidden, setIsUserHidden] = useState(true);

    const setCookie = (name, value, days) => {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    };

    useEffect(() => {
        const handleCookieChange = () => {
            setGenerations(getCookieValue('generations'));
            setModerations(getCookieValue('moderations'));
            setGuid(getCookieValue('guid'));
            setUsername(getCookieValue('username'));
            setIsMod(getCookieValue('isMod') === 'true');
        };

        window.addEventListener('cookieChange', handleCookieChange);
        window.addEventListener('modChange', handleCookieChange);

        return () => {
            window.removeEventListener('cookieChange', handleCookieChange);
            window.removeEventListener('modChange', handleCookieChange);
        };
    }, []);

    useEffect(() => {
        if (generations !== null) setCookie('generations', generations, 30); // Set cookie to expire in 30 days
        if (moderations !== null) setCookie('moderations', moderations, 30); // Set cookie to expire in 30 days
        if (guid !== null) setCookie('guid', guid, 30); // Set cookie to expire in 30 days
        if (username !== null) setCookie('username', username, 30); // Set cookie to expire in 30 days
        if (isMod !== null) setCookie('isMod', isMod.toString(), 30);
    }, [generations, moderations, guid, username, isMod]);

    useEffect(() => {
        const handleCookieChange = () => {
            setIsMod(getCookieValue('isMod') === 'true');
        };

        window.addEventListener('cookieChange', handleCookieChange);

        return () => {
            window.removeEventListener('cookieChange', handleCookieChange);
        };
    }, []);

    useEffect(() => {
        setCookie('isMod', isMod.toString(), 30);
    }, [isMod]);

    const value = {
        user: generations !== null && moderations !== null,
        generations,
        setGenerations,
        moderations,
        setModerations,
        guid,
        setGuid,
        username,
        setUsername,
        isMod,
        setIsMod,
        isUserHidden,
        setIsUserHidden
    };

    return (
        <UserContext.Provider value={value}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);