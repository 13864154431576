import React, { useState, useRef, useEffect } from 'react';
import Pagination from './Pagination';
import { useUser } from './UserContext';

const Footer = ({ page, setPage, isFiltered }) => {
    const [showForm, setShowForm] = useState(false);
    const [code, setCode] = useState('');
    const { setGenerations, setModerations, setUsername, user } = useUser();
    const inputRef = useRef(null);
    const [showSuccess, setShowSuccess] = useState(false);
    const [renderTime, setRenderTime] = useState(0);
    const [currentTime, setCurrentTime] = useState('');
    const dbURI = process.env.REACT_APP_DB_API_URL || 'http://192.168.0.83:3001';

    useEffect(() => {
        const startTime = performance.now();
        const now = new Date();
        const formattedTime = now.toLocaleString('en-GB', { hour: '2-digit', minute: '2-digit', year: 'numeric', month: '2-digit', day: '2-digit' })
            .replace(',', ':')
            .replace(/(\d{2})\/(\d{2})\/(\d{4})/, '$3/$2/$1')
            .trim();
        setCurrentTime(formattedTime);

        return () => {
            const endTime = performance.now();
            setRenderTime((endTime - startTime).toFixed(2));
        };
    }, []);

    useEffect(() => {
        if (showForm && inputRef.current) {
            inputRef.current.focus();
            const inputElement = inputRef.current;
            inputElement.addEventListener('touchstart', () => inputElement.focus());
        }
        return () => {
            if (inputRef.current) {
                const inputElement = inputRef.current;
                inputElement.removeEventListener('touchstart', () => inputElement.focus());
            }
        };
    }, [showForm]);

    const handleCodeSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${dbURI}/check-code`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ code })
            });
            const data = await response.json();
            if (data.valid) {
                setShowSuccess(true);
                setGenerations(data.generations);
                setModerations(data.moderations);
                setUsername(data.username);
                // console.log(typeof setGuid);
                // setGuid(data.guid);
                // console.log('Guid', data.guid);
                // setCookie('guid', data.guid, 30); // Set cookie to expire in 30 days
                document.cookie = `guid=${data.guid}; path=/`;
                // setCookie('generations', data.generations, 30); 
                // setCookie('moderations', data.moderations, 30); 
                
                setTimeout(() => {
                    setShowSuccess(false);
                }, 1500);
            } else {
                alert('Invalid code.');
            }
        } catch (error) {
            console.error('Error checking code:', error);
        }
        setShowForm(false);
    };

    const handleBackdropClick = () => {
        setShowForm(false);
    };

    return (
        <div className="footer">
            <div className="footer-content">
                <div className="created-by">
                    created by: RobotPirateNinja 
                    <br></br>
                    owned and operated by: RPN Omnigalactic Insitutution as of: {currentTime} in {renderTime} ms
                </div>
                <div className="footer-pagination">
                    <Pagination page={page} setPage={setPage} />
                </div>
                {!user && (
                    <div className="enter-code" onClick={() => setShowForm(!showForm)}>Enter Code</div>
                )}
            </div>
            {showForm && (
                <>
                    <div className="code-form-backdrop" onClick={handleBackdropClick}></div>
                    <div className="code-form">
                        <form onSubmit={handleCodeSubmit}>
                            <label>enter code: </label>
                            <input 
                                type="text" 
                                value={code} 
                                onChange={(e) => setCode(e.target.value)} 
                                ref={inputRef}
                            />
                            <button type="submit">Submit</button>
                        </form>
                    </div>
                </>
            )}
            {showSuccess && (
                <div className="code-form">
                    <div className="success-message">Code is valid!</div>
                </div>
            )}
        </div>
    );
};

export default Footer;
